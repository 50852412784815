import styled from "astroturf/index"
import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { TeamVideo } from "../TeamVideo"

export const AboutUs = () => {
  const [isVisibleTeamYears, setIsVisibleTeamYears] = React.useState(false)

  const onScrollToTeamYears = React.useCallback(
    isVisible => {
      if (!isVisibleTeamYears && isVisible) {
        setIsVisibleTeamYears(true)
      }
    },
    [isVisibleTeamYears]
  )

  return (
    <Wrapper>
      <Container>
        <ImageAlTeam>
          <VideoContainer>
            <TeamVideo builtIn={true} />
          </VideoContainer>
        </ImageAlTeam>

        <VisibilitySensor onChange={onScrollToTeamYears} partialVisibility>
          <RightBlock animation={isVisibleTeamYears}>
            <TeamYears>
              <AboutUsTitle>15 лет на рынке</AboutUsTitle>
              <AboutUsDescription>Компания основана в 2006 году</AboutUsDescription>
            </TeamYears>
            <TeamCount>
              <AboutUsTitle>82 специалиста</AboutUsTitle>
              <AboutUsDescription>Берем на себя разработку и техническую поддержку вашего продукта</AboutUsDescription>
            </TeamCount>
          </RightBlock>
        </VisibilitySensor>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
`

const Container = styled.div`
  height: 442px;
  display: flex;
  position: relative;
  margin-bottom: 100px;
  @media screen and (max-width: 1199px) {
    flex-wrap: wrap;
    height: auto;
    margin: 50px 0;
  }
`

const ImageAlTeam = styled.div`
  width: 55%;
  clip-path: polygon(0 0, 100% 0, 65% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 65% 100%, 0% 100%);
  position: relative;
  background: #c9ced4;
  @media (max-width: 1199px) {
    clip-path: none;
    -webkit-clip-path: none;
    margin-bottom: 24px;
    width: 100%;
    background: inherit;
  }
`

const VideoContainer = styled.div`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;

  @media (max-width: 1199px) {
    position: unset;
    overflow: hidden;
  }
`

const RightBlock = styled.div`
  position: absolute;
  width: 100%;
  left: 35.5%;
  top: 50px;
  clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(19% 0, 100% 0, 100% 100%, 0% 100%);
  height: 100%;
  display: flex;
  flex-direction: column;

  &.animation {
  }

  @media (max-width: 1199px) {
    position: inherit;
    left: 0;
    top: 0;
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
  }
  @media screen and (min-width: 1200px) {
    opacity: 0;
    &.animation {
      @import "mixin.scss";
      @include animate-incision;
    }
  }
`

const TeamYears = styled.div`
  flex-grow: 1;
  margin-bottom: 24px;
  background-color: #232c37;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 475px;
  justify-content: center;

  @media (max-width: 2200px) {
    padding: 0 0 0 370px;
  }

  @media (max-width: 1700px) {
    padding: 0 0 0 310px;
  }

  @media (max-width: 1500px) {
    padding: 0 0 0 260px;
  }

  @media (max-width: 1200px) {
    padding: 0 0 0 247px;
  }

  @media screen and (max-width: 1199px) {
    padding-left: 0;
    text-align: center;
    min-height: 209px;
  }

  @media screen and (max-width: 596px) {
    min-height: 196.5px;
  }
`

const TeamCount = styled.div`
  flex-grow: 1;
  background-color: #232c37;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 225px;
  justify-content: center;

  @media (max-width: 2200px) {
    padding: 0 0 0 176px;
  }

  @media screen and (max-width: 1199px) {
    text-align: center;
    min-height: 209px;
    padding: 0 0 0 146px;
  }
  @media screen and (max-width: 596px) {
    padding: 0;
    min-height: 221.5px;
  }
`

const AboutUsTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.42857143;
  @media screen and (max-width: 991px) {
    font-size: 36px;
  }
`

const AboutUsDescription = styled.div`
  font-size: 20px;
  color: #ffffff;
  width: 55%;
  line-height: 1.42857143;
  @media screen and (max-width: 1199px) {
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
`
