import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyOurActivityImg4 = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "ourActivity/4.jpg"}) {
          id
          childImageSharp {
            fixed (width:464, height:264, quality: 80)  {
              ...GatsbyImageSharpFixed
            }
          }
      }
    }
  `)
  return <Img style={{height:"264px"}} fluid={data.file.childImageSharp.fixed} alt=""/>
}
