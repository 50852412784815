import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbySkImg = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "ourClientsPng/sk.png"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  `)
  return <Img fluid={data.file.childImageSharp.fluid} alt="логотип Сколково"/>
}
