import styled from "astroturf/index"
import React from "react"
import { ourCases } from "../../../constants/ourCases"
import moreCases from "../../../images/moreArrow.svg"
import { Title } from "../../../ui/commonStyles"
import { WidthContainer } from "../../../ui/WidthContainer"
import { OurCase } from "./OurCase"

export const OurCases = () => {
  const cases = React.useMemo(() => {
    return ourCases.map((item, index) => <OurCase {...item} key={index} />)
  }, [])

  return (
    <WidthContainer>
      <Container>
        <CustomTitle>Наши кейсы</CustomTitle>
        <Column>{cases}</Column>
        <MoreCases href={"/Ru/Page/InDevelopment"} aria-label={"Dex"}>
          <div>Больше кейсов</div>
          <MoreCasesIcon src={moreCases} alt={"Больше кейсов"} />
        </MoreCases>
      </Container>
    </WidthContainer>
  )
}

const Container = styled.div`
  margin-top: 80px;
  position: relative;
  @media screen and (max-width: 1199px) {
    margin-top: 0;
    padding-top: 40px;
  }
`

const CustomTitle = styled(Title)`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -20px;

  @media (max-width: 767px) {
    position: unset;
    margin-top: 0;
    text-align: center;
  }
`

const Column = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 70px;
  flex-direction: column;
  flex: 1;

  & > :nth-child(2n + 1) {
    margin-top: 70px;
  }

  & > :nth-child(2n) {
    margin-bottom: 70px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;

    padding-top: 30px;

    & > :nth-child(n) {
      margin: 0;
    }
  }
`

const MoreCases = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #2669cc;
  cursor: pointer;

  &:hover {
    > img {
      transform: translateX(10px);
    }
  }
  & > :not(:first-child) {
    margin-left: 24px;
  }

  @media (max-width: 767px) {
    position: unset;
    margin-top: 40px;
    justify-content: center;
  }
`

const MoreCasesIcon = styled.img`
  width: 48px;
  transition: 0.1s ease-in-out;
`
