import styled from "astroturf/index"
import React from "react"
import { OldWhiteButton } from "../../../ui/Buttons"
import VisibilitySensor from "react-visibility-sensor"

export const RowCost = ({ title, description, buttonText, buttonClick }) => {
  const [firstIsVisible, setFirstIsVisible] = React.useState(false)

  const scrollToElement = React.useCallback(
    isVisible => {
      if (!firstIsVisible && isVisible) {
        setFirstIsVisible(true)
      }
    },
    [firstIsVisible]
  )

  return (
    <VisibilitySensor onChange={scrollToElement}>
      <Row animation={firstIsVisible}>
        <CostTitle>{title}</CostTitle>
        <CostDescription dangerouslySetInnerHTML={{ __html: description }} />
        <ButtonContainer>
          <OldWhiteButton onClick={buttonClick}>{buttonText}</OldWhiteButton>
        </ButtonContainer>
      </Row>
    </VisibilitySensor>
  )
}

const Row = styled.div`
  visibility: visible;
  box-sizing: border-box;
  opacity: 0;

  &.animation {
    @import "mixin.scss";
    @include rating-block-left-animate;
  }
`

const CostTitle = styled.div`
  font-size: 34px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 70px;
  line-height: 1.4;
`

const CostDescription = styled.div`
  font-size: 18px;
  color: #ffffff;
  line-height: 1.4;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
`
