import styled from "astroturf/index"
import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

export const OldRatingItem = ({ top, middle, bottom, description, right = false, delay = 0 }) => {
  const [visible, setVisible] = useState(false)

  const onScrollToElement = React.useCallback(
    isVisible => {
      if (!visible && isVisible) {
        setTimeout(() => {
          setVisible(true)
        }, delay)
      }
    },
    [visible, delay]
  )

  return (
    <VisibilitySensor onChange={onScrollToElement}>
      <RatingItem right={right} left={!right} animation={visible}>
        <RatingBlock>
          <DescriptionTop>{top}</DescriptionTop>
          <DescriptionMiddle>{middle}</DescriptionMiddle>
          <DescriptionBottom>{bottom}</DescriptionBottom>
        </RatingBlock>
        <RatingDescription>{description}</RatingDescription>
      </RatingItem>
    </VisibilitySensor>
  )
}

const RatingItem = styled.div`
  position: relative;
  visibility: visible;
  width: 25%;
  padding: 0 15px;
  box-sizing: border-box;
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  /* transition: all 1s ease-in-out; */
  &.left {
    opacity: 0;

    &.animation {
      @import "mixin.scss";
      @include rating-block-left-animate;
    }
  }
  &.right {
    opacity: 0;

    &.animation {
      @import "mixin.scss";
      @include rating-block-right-animate;
    }
  }
`

const RatingBlock = styled.div`
  background-image: url("../../../images/pageHome/award-icon.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 991px) {
    padding-top: 40px;
  }
`

const DescriptionTop = styled.div`
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: #212121;
  margin-top: 10px;
  line-height: 37px;
`

const DescriptionMiddle = styled.div`
  font-size: 14px;
  text-align: center;
  color: #212121;
  line-height: 20px;
`

const DescriptionBottom = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1f2328;
  margin-top: 10px;
  line-height: 22px;
`

const RatingDescription = styled.div`
  font-size: 18px;
  text-align: center;
  color: #1f2328;
  margin-top: 10px;
`
