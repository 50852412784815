import React, { useRef, useEffect, useMemo } from "react"
import styled from "astroturf"
import arrowPrew from "../../images/arrow-prev.svg"
import arrowNext from "../../images/arrow-next.svg"
import { useWindowSize } from "../../hooks/useWindowSize"
import { BootstrapContainer } from "../../ui/BootstrapContainer"

export const TrustingCompany = ({ clickNext, clickPrev, data, onLayout, minHeight }) => {
  const textRef = useRef()
  const { width } = useWindowSize()
  const { author, logo, review, maxHeight } = data
  const { avatar, name, position } = author

  useEffect(() => {
    if (maxHeight && textRef.current) {
      const currentHeight = textRef.current.getBoundingClientRect().height
      if (minHeight !== currentHeight) {
        onLayout(currentHeight)
      }
    }
  }, [maxHeight, width, minHeight, onLayout])

  const styleProps = useMemo(() => (minHeight ? { minHeight } : {}), [minHeight])

  return (
    <WidthContainer>
      <Container>
        <ArrowButtonContainer onClick={clickPrev} left>
          <ArrowButton src={arrowPrew} alt={"prev"} />
        </ArrowButtonContainer>
        <InnerContainer>
          <InfoRow>
            <Avatar src={avatar} alt={"avatar"} />
            <ReviewerColumn>
              <ReviewerName>{name}</ReviewerName>
              <ReviewerPosition>{position}</ReviewerPosition>
            </ReviewerColumn>
            <ReviewerOrganizationLogo src={logo} alt={"logo Company"} />
          </InfoRow>
          <Rewiew ref={textRef} style={styleProps}>
            {review}
          </Rewiew>
          <ReviewerOrganizationLogoMobile src={logo} alt={"logo Company"} />
        </InnerContainer>
        <ArrowButtonContainer onClick={clickNext} right>
          <ArrowButton src={arrowNext} alt={"next"} />
        </ArrowButtonContainer>
      </Container>
    </WidthContainer>
  )
}

const WidthContainer = styled(BootstrapContainer)``

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 30px 24px 60px;
  @media screen and (max-width: 1199px) {
    padding: 20px 30px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  object-fit: cover;
  box-sizing: border-box;
`

const ReviewerColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @media screen and (max-width: 1199px) {
    margin-left: 15px;
  }
`

const ReviewerName = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  @media screen and (max-width: 1199px) {
    font-size: 20px;
  }
`

const ReviewerPosition = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-top: 5px;
  @media screen and (max-width: 1199px) {
    font-size: 14px;
    line-height: 100%;
  }
`

const ReviewerOrganizationLogo = styled.img`
  margin-left: auto;
  height: 44px;
  width: auto;
  object-fit: contain;
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
`

const ReviewerOrganizationLogoMobile = styled.img`
  align-self: center;
  height: 44px;
  width: auto;
  object-fit: contain;
  margin-top: 30px;
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
`

const Rewiew = styled.div`
  font-size: 20px;
  line-height: 32px;

  color: #000000;
  margin-top: 30px;

  @media screen and (min-width: 1360px) and (max-width: 1599px) {
    min-height: 96px;
  }
  @media screen and (min-width: 684px) and (max-width: 1359px) {
    min-height: 128px;
  }

  @media screen and (min-width: 603px) and (max-width: 683px) {
    min-height: 160px;
  }

  @media screen and (min-width: 513px) and (max-width: 602px) {
    min-height: 192px;
  }

  @media screen and (min-width: 467px) and (max-width: 512px) {
    min-height: 224px;
  }

  @media screen and (min-width: 430px) and (max-width: 466px) {
    min-height: 256px;
  }

  @media screen and (min-width: 396px) and (max-width: 429px) {
    min-height: 288px;
  }

  @media screen and (min-width: 375px) and (max-width: 395px) {
    min-height: 320px;
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {
    min-height: 416px;
  }

  @media screen and (max-width: 1199px) {
    margin-top: 20px;
  }
`

const ArrowButtonContainer = styled.div`
  padding: 8px;
  cursor: pointer;
  @media screen and (max-width: 1199px) {
    display: none;
  }
  &.left {
    margin-right: 72px;
  }
  &.right {
    margin-left: 72px;
  }
`

const ArrowButton = styled.img`
  width: 8px;
`
