import styled from "astroturf"
import React, { useRef, useState } from "react"
import joinToDexVideo from "../video/joinToDex.mp4"
import playIcon from "../images/play.svg"
import pauseIcon from "../images/pause.svg"
import fullScreenIcon from "../images/fulscreen.svg"
import fullScreenCollapseIcon from "../images/fulscreenCollapse.svg"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"


export const TeamVideo = ({ builtIn = false}) => {
  const [play, setPlay] = useState(false)
  const [start, setStart] = useState(true)
  const [fullSize, setFullSize] = useState(false)

  const videoRef = useRef()
  const onStart = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setPlay(true)
      setStart(false)
    }
  }

  const onPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setPlay(false)
    }
  }

  const onClickVideo = () => {
    if (play) {
      onPause()
    } else {
      onStart()
    }
  }

  const onClickFullscreen = () => {
    setFullSize(prev => !prev)
    setTimeout(() => {
      videoRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }, 50)
  }

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "team-video.png"}) {
          childImageSharp {
            fluid(quality:100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      }
    }
  `)


  return (
    <Container>
      {!play && start && (
        <PlayContainer onClick={onClickVideo}>
          <Play src={playIcon} alt={"play"} />
        </PlayContainer>
      )}
      {!start && (
        <PlayContainer onClick={onClickVideo} as="a">
          {play ? <Play src={pauseIcon} /> : <Play src={playIcon} />}
        </PlayContainer>
      )}
      {!play && start && <StubImage fluid={data.file.childImageSharp.fluid} builtIn={builtIn} />}
      {!start && !builtIn && (
        <FullscreenIconContainer onClick={onClickFullscreen}>
          <FullscreenIcon
            src={!fullSize ? fullScreenIcon : fullScreenCollapseIcon}
          />
        </FullscreenIconContainer>
      )}
      <Video
          preload={"none"}
        ref={videoRef}
        onClick={onClickVideo}
        fullSize={fullSize}
        builtIn={builtIn}
      >
        <source type="video/mp4" src={joinToDexVideo} />
      </Video>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  max-height: 442px;

  & > a {
    display: none;
  }

  &:hover > a {
    display: flex;
  }

  overflow: hidden;
`

const StubImage = styled(Img)`
  position: absolute!important;
  top: 0;
  height: 479px;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  @media screen and (max-width: 1199px) {
    height: 280px;
  }

  &.builtIn {
    height: 442px !important;
    @media screen and (max-width: 1199px) {
      height: 100% !important;
      max-height: 280px;
    }
  }
`

const Video = styled.video`
  width: 100%;
  height: 479px;
  object-fit: cover;
  @media screen and (max-width: 1199px) {
    height: 280px;
  }

  &.fullSize {
    width: 100%;
    height: auto;
    @media screen and (max-width: 1199px) {
      height: auto;
    }
  }

  &.builtIn {
    height: 442px !important;
    @media screen and (max-width: 1199px) {
      height: auto !important;
      max-height: 280px;
    }
  }
`

const PlayContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  user-select: none;
`

const Play = styled.img`
  width: 80px;
  height: 80px;
  user-select: none;
`

const FullscreenIconContainer = styled.a`
  padding: 24px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  &:hover {
    opacity: 0.5;
  }
  cursor: pointer;
`

const FullscreenIcon = styled.img`
  width: 24px;
  height: 24px;
`
