import React from "react"
import styled from "astroturf/index"

export const OurCase = ({ title, text, image, href, logo, smallLogo }) => {
  return (
    <Container href={`${href}`}>
      <Image>{image(text)}</Image>
      <SubImage>{image(text)}</SubImage>
      <TextContainer>
        {logo && (
          <>
            <LogoContainer>{logo(title || text)}</LogoContainer>
            <SmallLogoContainer>{smallLogo(title || text)}</SmallLogoContainer>
          </>
        )}
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextContainer>
    </Container>
  )
}

const Container = styled.a`
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: 0.15s;
  &:hover {
    transform: scale(0.95);
  }

  overflow: hidden;
`

const Image = styled.div`
  width: 100%;
  opacity: 0;
`

const SubImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: 1;
  vertical-align: middle;
  &:hover {
    transform: scale(1.05);
  }
  transition: 0.15s;
`
const LogoContainer = styled.div`
  margin-bottom: 18px;

  @media (max-width: 420px) {
    display: none;
  }
`

const SmallLogoContainer = styled.div`
  margin-bottom: 0;
  display: none;

  @media (max-width: 420px) {
    display: block;
  }
`

const TextContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media (max-width: 420px) {
    top: 25px;
    left: 20px;
    right: 20px;
  }
`

const Title = styled.span`
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.02em;
  color: #ffffff;
  @media screen and (max-width: 1199px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 420px) {
    font-size: 20px;
  }
`

const Text = styled("span")`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 8px;
  @media screen and (max-width: 1199px) {
    font-size: 14px;
    line-height: 18px;
  }
`

// const Logo = styled.img`
//   width: fit-content;
//   margin-bottom: 18px;
//
//   @media (max-width: 420px) {
//     width: 45px;
//     margin-bottom: 10px;
//   }
// `
