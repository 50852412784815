import React, { useState, useEffect, useRef } from "react"
import styled from "astroturf/index"
import { useWindowSize } from "../../hooks/useWindowSize"
import { ourClients } from "../../constants/ourClients"

export const OurClients = () => {
  const [scroll, setScroll] = useState(0)
  const innerContainerRef = useRef()
  const { width: windowWidth } = useWindowSize()

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY
      if (currentScroll !== scroll) {
        setScroll(currentScroll)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scroll])

  const { height } = useWindowSize()

  const speedCoefficient = React.useMemo(() => {
    return windowWidth >= 1200 ? 0.125 : windowWidth <= 600 ? 0.25 : 0.4
  }, [windowWidth])

  const translateX = React.useMemo(() => {
    return (speedCoefficient * ((7740 - windowWidth) * scroll)) / height > 7740 - windowWidth
      ? 7740 - windowWidth
      : (speedCoefficient * ((7740 - windowWidth) * scroll)) / height
  }, [scroll, speedCoefficient, windowWidth, height])

  const company = React.useMemo(() => {
    return ourClients.map(({ logo, href, alt }, ind) => (
      <Company {...(href ? { href: `${href}` } : {})} key={ind}>
        {logo()}
      </Company>
    ))
  }, [])

  return (
    <Container>
      <InnerContainer style={{ transform: `translateX(-${translateX}px)` }} ref={innerContainerRef}>
        {company}
        {company}
        {company}
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  z-index: 1;
  padding: 50px 0;
  background-color: #f4f7fc;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    /* display: none; */
    padding: 20px 0;
  }
`

const Company = styled.a`
  min-width: 215px;
  height: 64px;
  object-fit: contain;

  @media screen and (max-width: 1199px) {
    width: 162px;
    height: 48px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
