import { GatsbyCianImg } from "../images/ourClientsPng/GatsbyCianImg"
import { GatsbyNokiaImg } from "../images/ourClientsPng/GatsbyNokiaImg"
import { GatsbySberbankImg } from "../images/ourClientsPng/GatsbySberbankImg"
import { GatsbyMTCImg } from "../images/ourClientsPng/GatsbyMTCImg"
import { GatsbyBig3Img } from "../images/ourClientsPng/GatsbyBig3Img"
import { GatsbyPapaJohnsImg } from "../images/ourClientsPng/GatsbyPapaJohnsImg"
import { GatsbyVTBImg } from "../images/ourClientsPng/GatsbyVTBImg"
import { GatsbySkImg } from "../images/ourClientsPng/GatsbySkImg"
import { GatsbyPikImg } from "../images/ourClientsPng/GatsbyPikImg"

export const ourClients = [
  {
    logo: GatsbyCianImg,
    href: "",
  },
  {
    logo: GatsbyNokiaImg,
    href: "",
  },
  {
    logo: GatsbySberbankImg,
    href: "",
  },
  {
    logo: GatsbyMTCImg,
    href: "",
  },
  {
    logo: GatsbyBig3Img,
    href: `/Page/PortfolioMobile/big3`,
  },
  {
    logo: GatsbyPapaJohnsImg,
    href: "",
  },
  {
    logo: GatsbyVTBImg,
    href: "",
  },
  {
    logo: GatsbySkImg,
    href: "",
  },
  {
    logo: GatsbyPikImg,
    href: "",
  },
]
