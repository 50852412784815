import aleshkov from "../images/trustingCompanies/aleshkov.jpg"
import andrei from "../images/trustingCompanies/andrei.jpg"
import tulin from "../images/trustingCompanies/tulin.jpg"
import cdn from "../images/trustingCompanies/cdn.png"
import planeta from "../images/trustingCompanies/planeta.png"
import prosocyal from "../images/trustingCompanies/prosocyal.png"

export const trustingCompanies = [
  {
    id: 3,
    author: {
      avatar: andrei,
      name: "Андрей Гришин",
      position: "CTO & Founder",
    },
    logo: cdn,
    review: `Dex оказалась одной из немногих команд, коллектив которой обладает необходимыми практическими знаниями для выполнения задуманных нами сложных технических задач.
    `,
  },
  {
    id: 2,
    author: {
      avatar: aleshkov,
      name: "Дмитрий Алешков",
      position: "CTO",
    },
    logo: prosocyal,
    review: `С первой Skype-встречи почувствовал профессиональный подход к делу. Мы добились результатов больших, чем те, на которые рассчитывали. Получили очень много предложений и советов по развитию нашей идеи.`,
  },
  {
    id: 1,
    author: {
      avatar: tulin,
      name: "Виктор Тюлин",
      position: "Директор",
    },
    logo: planeta,
    review: `Выбор компании Dex был основан на рекомендации наших партнёров. Главной задачей было создание максимально простой и удобной системы для ведения клиентов, и последующая ее интеграция с нашей системой бухучета`,
    maxHeight: true,
  },
]
