import { GatsbyOurCaseBig3Img } from "../images/ourCases/GatsbyOurCaseBig3Img"
import { GatsbyOurCaseHelloHabitImg } from "../images/ourCases/GatsbyOurCaseHelloHabitImg"
import { GatsbyOurCaseNokiaImg } from "../images/ourCases/GatsbyOurCaseNokiaImg"
import { GatsbyOurCaseNokiaLogo } from "../images/ourCases/GatsbyOurCaseNokiaLogo"
import { GatsbyOurCasePikImg } from "../images/ourCases/GatsbyOurCasePikImg"
import { GatsbyOurCasePikLogo } from "../images/ourCases/GatsbyOurCasePikLogo"
import { GatsbyOurCaseDiggiDealImg } from "../images/ourCases/GatsbyOurCaseDiggiDealImg"
import { GatsbyOurCaseLuxxyImg } from "../images/ourCases/GatsbyOurCaseLuxxyImg"
import { GatsbyOurCasePikLogoSmall } from "../images/ourCases/GatsbyOurCasePikLogoSmall"
import { GatsbyOurCaseNokiaLogoSmall } from "../images/ourCases/GatsbyOurCaseNokiaLogoSmall"

export const ourCases = [
  {
    title: `СМИ для лиги BIG3`,
    text: `Мобильное приложение получило награды Международное Social Media и Лучшее приложение Медиа / СМИ`,
    image: GatsbyOurCaseBig3Img,
    href: `/Page/PortfolioMobile/big3`,
  },
  {
    title: `Трекер HelloHabbit`,
    text: `Социальная сеть для выработки полезных навыков`,
    image: GatsbyOurCaseHelloHabitImg,
    href: `/Page/PortfolioMobile/hellohabit`,
  },
  {
    // title: `NOKIA`,
    logo: GatsbyOurCaseNokiaLogo,
    smallLogo: GatsbyOurCaseNokiaLogoSmall,
    text: `Личный веб-кабинет покупателей для компании Nokia`,
    image: GatsbyOurCaseNokiaImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    // title: `ПИК Группа`,
    logo: GatsbyOurCasePikLogo,
    smallLogo: GatsbyOurCasePikLogoSmall,
    text: `Веб-сервис внутреннего документооборота для крупнейшего застройщика России`,
    // backgroundText: "gray",
    image: GatsbyOurCasePikImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    title: `Маркетплейс DIGGI Deal`,
    text: `Специализированный маркетплейс по продаже скидочных карт`,
    image: GatsbyOurCaseDiggiDealImg,
    // TODO - need right link
    href: `/Page/InDevelopment`,
  },
  {
    title: `LUXXY Outlet`,
    text: `Мобильное приложение для продажи товаров класса Люкс.`,
    image: GatsbyOurCaseLuxxyImg,
    href: `/Page/PortfolioMobile/luxxy`,
  },
]
